import React from "react"
import styled, { withTheme } from "styled-components"

import Raconteur from "../../images/brands/raconteur.svg"
import Times from "../../images/brands/times.svg"
import Freuds from "../../images/brands/freuds.svg"

const Brands = ({ theme }: any) => {
  const Brand = styled.div`
    max-width: 725px;
    margin: 3rem auto 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-align: center;
    @media screen and (max-width: ${theme.breakpoints.phone}) {
      flex-direction: column;
      span {
        flex-direction: row;
        text-align: center;
        margin: 1rem 0;
      }
      svg {
        width: 60% !important;
        display: block;
        margin: 0 auto;
      }
    }

    svg {
      width: 25%;
      max-height: 85px;
      fill: ${theme.colors.light};
    }
    span {
      font-size: 1.5rem;
      color: ${theme.colors.accent};
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  `

  return (
    <Brand>
      <Raconteur />
      <span>+</span>
      <Times />
      <span>+</span>
      <Freuds />
    </Brand>
  )
}

export default withTheme(Brands)
