import React from "react"
import PropTypes from "prop-types"
import withQuery from "../hoc/withQuery"
import styled from "styled-components"

import Layout from "../components/layout/layout"
import Container, { Row } from "../components/layout/container"
import SEO from "../components/seo/seo"
import Brands from "../components/ui/brands"
import Cta from "../components/ui/cta"
import List from "../components/ui/list"

import Discover from "../images/illustrations/discover.svg"
import Craft from "../images/illustrations/craft.svg"
import Tell from "../images/illustrations/tell.svg"

const Top = styled.section`
  background: ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.light};
  margin-top: -2rem;
  padding: 2rem 0;
  min-height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: center;

  h1 {
    color: ${props => props.theme.colors.secondary};
    max-width: 850px;
    margin: 0 auto 2rem;
    padding-top: 1.5rem;
    font-size: 70px;
    text-align: center;
    line-height: 1;
    border-top: 1px solid ${props => props.theme.colors.light};
    @media screen and (max-width: ${props => props.theme.breakpoints.phone}) {
      font-size: 40px;
      padding: 1.5rem 1rem 0;
      margin-bottom: 1.5rem;
    }
  }
  h2 {
    color: ${props => props.theme.colors.light};
    font-size: 30px;
    margin: 0 auto 2rem;
    text-align: center;
    max-width: 800px;
  }
  p {
    max-width: 850px;
    text-align: center;
    margin: 0 auto;
    box-sizing: border-box;
    padding: 0 5rem 1.5rem;
    border-bottom: 1px solid ${props => props.theme.colors.light};
    @media screen and (max-width: ${props => props.theme.breakpoints.phone}) {
      padding: 0 0 1.5rem;
    }
  }
`

const Third = styled.div`
  width: 30%;
  @media screen and (max-width: ${props => props.theme.breakpoints.phone}) {
    text-align: center;
    width: 100%;
    svg {
      display: block;
      margin: 0 auto;
    }
  }
`

const H2 = styled.h2`
  color: ${props => props.theme.colors.primary};
  margin: 3rem 0 1rem;
`

const H3 = styled.h3`
  font-size: 35px;
  margin-top: 0;
  margin-bottom: 1.5rem;
  @media screen and (max-width: ${props => props.theme.breakpoints.phone}) {
    font-size: 40px;
  }
  span {
    font-size: 25px;
    font-weight: normal;
    font-style: italic;
    color: ${props => props.theme.colors.secondary};
    @media screen and (max-width: ${props => props.theme.breakpoints.phone}) {
      font-size: 25px;
    }
  }
  & + svg {
    width: 200px;
    height: 200px;
  }
`

const Programme = styled.section`
  padding: 3rem 0;
  margin: 5rem 0 0;
  background: ${props => props.theme.colors.light};

  h2 {
    color: ${props => props.theme.colors.primary};
    margin: 0 0 2rem;
  }
`

interface IProps {
  search: {
    name: string
  }
}

const CustomQueryStringComponent = ({ search }: IProps) => {
  const { name } = search
  return (
    <Layout>
      <SEO title={`Hi ${name ? name.replace(/_/g, " ") : "there"} from amp!`} />
      <Top id="home">
        <Container>
          <h1>Hi {name ? name.replace(/_/g, " ") : "there!"}</h1>
          <h2>
            Your visibility amongst employees, investors and customers is
            critical
          </h2>
          <p>
            <strong>As CEO, you need to raise your profile.</strong>
          </p>
          <Brands />
        </Container>
      </Top>
      <Container>
        <H2>Here's how, introducing amp:</H2>
        <p>
          A three-step CEO training and development programme that will
          transform you into an industry leader and online influencer.
        </p>
        <Row>
          <Third>
            <H3>
              Discover <span>your story</span>
            </H3>
            <Discover />
          </Third>
          <Third>
            <H3>
              Craft <span>your story</span>
            </H3>
            <Craft />
          </Third>
          <Third>
            <H3>
              Tell <span>your story</span>
            </H3>
            <Tell />
          </Third>
        </Row>
      </Container>
      <Programme>
        <Container>
          <h2>
            The <em>amp</em> programme includes:
          </h2>
          <List>
            <li>
              a dedicated content strategist who will work with you across
              multiple modules to find the stories, grounded in SEO research,
              which will resonate with your key audiences and ultimately build
              trust
            </li>
            <li>
              a full day consultation with a tier one journalist from your
              industry to help you find your authentic editorial voice
            </li>
            <li>
              a bespoke media training day at freuds’ studios, stress testing
              your media presentation skills and preparing you for good and bad
              media exposure
            </li>
            <li>
              a bespoke content creation and distribution plan, including online
              articles with guaranteed views, talking head and social videos, an
              article in <em>The Times</em> and much more, ensuring your
              messages get heard by those who matter
            </li>
          </List>
        </Container>
      </Programme>
      <Container>
        <Cta ceoName={name && name} />
      </Container>
    </Layout>
  )
}

CustomQueryStringComponent.propTypes = {
  search: PropTypes.object,
}

export default withQuery(CustomQueryStringComponent)
